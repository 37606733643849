<template>
  <vee-form
    @submit="onSubmit"
    v-slot="{ errors }"
    :validation-schema="schema"
  >
    <div class="form-group">
      <label> Montant <span class="text-danger">*</span></label>
      <vee-field
        class="form-control"
        placeholder="Montant"
        name="amount"
        :class="{ 'is-invalid': errors.amount }"
        type="number"
      />
      <span class="text-danger d-block"><ErrorMessage name="amount" /></span>
    </div>
    <div class="form-group">
      <label> Commentaire <span class="text-danger">*</span></label>
      <vee-field
        name="notes"
        placeholder="Commentaire"
        class="form-control"
        :class="{ 'is-invalid': errors.notes }"
      />
      <span class="text-danger d-block"><ErrorMessage name="notes" /></span>
    </div>
    <div class="form-group float-right ml-2 d-inline">
      <button class="btn btn-primary"><i class="fas fa-save"></i> Enregistrer</button>
    </div>
  </vee-form>
</template>
<script>
import { mapGetters } from "vuex";
import CashierService from "@/services/cashier.service";
const cashierService = new CashierService();

export default {
  name: "MakeOperation",
  props: ["cashiers", "storeId", "type"],
  methods: {
    onSubmit(values, { resetForm }) {
      values.store_id = this.storeId;
      values.type = this.type;
      console.log(values, 'values');
      this.makeCashOperation(values);
      resetForm();
    },
    async makeCashOperation(data) {
      try {
        await cashierService.store(data);
        this.$toast.success("Operation enregistré", {
          position: "top-right",
          timeout: 5000,
        });
        this.$parent.$options.methods.closeModal();
        this.$emit("getCash");
      } catch (err) {
        console.log("cashier operation Error", err);
      }
    },
  },
  computed: {
    ...mapGetters("cash", {
      getBalances: "getBalances",
    }),
    schema() {
      return {
        amount:
          this.type == "withdrawal"
            ? `required|max_value:${this.getBalances.expected_balance}`
            : "required",
        notes: "required",
      };
    },
  },
};
</script>
