<template>
  <td>
    {{ dateFormat(cashier.created_at, "DD/MM/YYYY, HH:mm:ss") }}
  </td>
  <td v-if="cashier.type == 'withdrawal'">{{ "Retrait" }}</td>
  <td v-else-if="cashier.type == 'deposit'">{{ "Dépôt" }}</td>
  <td v-else>{{ "Vérification" }}</td>
  <td v-if="cashier.type == 'deposit'" class="text-right">
    <span class="text-success"
      >+ {{ numFormat(cashier.amount) }}</span
    >
  </td>
  <td v-else-if="cashier.type == 'withdrawal'" class="text-right">
    <span class="text-danger">{{ numFormat(cashier.amount) }}</span>
  </td>
  <td v-else class="text-right">
    <span class="text-warning">{{ numFormat(cashier.amount) }}</span>
  </td>
  <td>{{ userFullName }}</td>
  <td>{{ cashier.notes }}</td>
  <td class="d-flex justify-content-around">
    <button
      v-if="cashier.status == 'active'"
      :disabled="cashier.type == 'verification'"
      @click="editOperation(cashier)"
      class="btn btn-light border border-primary btn-sm text-primary"
    >
      <i class="fa fa-edit"></i>
    </button>
    <button
      v-if="cashier.status == 'active' && user_has_access_to_delete"
      @click="confirmDelete(cashier.id, cashier.type)"
      class="btn btn-light border border-danger btn-sm text-danger"
    >
      <i class="fa fa-trash"></i>
    </button>
  </td>
</template>
<script>
import mixins from "@/includes/mixins";

export default {
  name: "CashComposition",
  mixins: [mixins],
  props: {
    cashier: {
      type: Object,
      required: true,
    },
    user_has_access_to_delete: {
      type: Boolean,
      required: true,
    },
    editOperation: {
      type: Function,
      required: true,
    },
    confirmDelete: {
      type: Function,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
  },
  computed: {
    userFullName() {
      let user = _.find(this.users, { id: this.cashier.created_by });
      return user.fullname;
    },
  },
  methods: {
    numFormat(number) {
      return this.$filters.numFormat(number);
    },
  },
};
</script>
<style scoped>
.filter-icon i:hover{
   color: black !important;
}
</style>