<template>
  <vee-form
    @submit="onSubmit"
    v-slot="{ errors }"
    :initial-values="formValues"
    :validation-schema="schema"
  >
    <div class="form-group">
      <label label>Type <span class="text-danger">*</span></label>
      <vee-field name="type" v-model="type" as="select" class="form-control">
        <option class="d-none" value="" disabled>Dépôt</option>
        <option value="deposit">Dépôt</option>
        <option value="withdrawal">Retrait</option>
      </vee-field>
    </div>
    <div class="form-group">
      <label> Montant <span class="text-danger">*</span></label>
      <vee-field
        class="form-control"
        name="amount"
        placeholder="Montant"
        :class="{ 'is-invalid': errors.amount }"
        type="number"
      />
      <span class="text-danger d-block"><ErrorMessage name="amount" /></span>
    </div>
    <div class="form-group">
      <label> Commentaire <span class="text-danger">*</span></label>
      <vee-field
        name="notes"
        placeholder="Commentaire"
        class="form-control"
        :class="{ 'is-invalid': errors.notes }"
      />
      <span class="text-danger d-block"><ErrorMessage name="notes" /></span>
    </div>
    <div class="form-group float-right ml-2 d-inline">
      <button class="btn btn-primary"><i class="fas fa-save"></i> Enregistrer</button>
    </div>
  </vee-form>
</template>
<script>
import { mapGetters } from "vuex";
import CashierService from "@/services/cashier.service";

const cashierService = new CashierService();

export default {
  name: "EditOperation",
  props: ["cashiers", "storeId"],
  data() {
    return {
      schema: {
        amount: "required",
        notes: "required",
      },
      error: null,
      isLoading: false,
      type: null,
    };
  },
  created() {
    this.type = this.getCashToEdit.type;
    this.schema = {
      amount:
        this.type == "withdrawal"
          ? `required|max_value:${this.getBalances.expected_balance}`
          : "required",
      notes: "required",
    };
  },

  methods: {
    onChange(e) {
      this.type = e.target.value;
    },
    onSubmit(values, { resetForm }) {
      values.store_id = this.storeId;
      this.editCashOperation(values);
      resetForm();
    },
    async editCashOperation(data) {
      try {
        const res = await cashierService.update(data, this.getCashToEdit.id);
        this.$toast.success("Operation modifié", {
          position: "top-right",
          timeout: 5000,
        });
        this.$parent.$options.methods.closeModal();
        this.$emit("getCash");
      } catch (err) {
        console.log("cashier operation Error", err);
      }
    },
  },
  computed: {
    ...mapGetters("cash", {
      getCashToEdit: "getCashToEdit",
      getBalances: "getBalances",
    }),
    formValues() {
      return {
        type: this.getCashToEdit.type,
        amount: this.getCashToEdit.amount,
        notes: this.getCashToEdit.notes,
      };
    },
  },
  watch: {
    type(newValue) {
      if (newValue === "withdrawal") {
        this.schema = {
          amount: `required|max_value:${this.getBalances.expected_balance}`,
          notes: "required",
        };
      } else {
        this.schema = {
          amount: `required`,
          notes: "required",
        };
      }
    },
  },
};
</script>
