<template>
  <div class="container-fluid">
    <div class="row no-gutters mt-3">
      <div class="col-12">
        <base-breadcrumb :pageName="currentPageName" :storeName="''" />
      </div>
    </div>
  </div>
  <base-loading v-if="isLoading" />
  <div class="row no-gutters" v-else>
    <div class="col-12">
      <div class="container-fluid">
        <div class="table-card card p-4">
          <div class="d-flex">
            <div class="h3 px-2 mr-auto p-2">
              Fond de Caisse attendu :
              <strong class="text-info">{{ numFormat(expected_balance) }}</strong>
            </div>
            <div>
              <router-link class="btn btn-info mr-1" to="/cashcheck">
                <i class="fas fa-archive"></i> vérification de caisse
              </router-link>
            </div>
            <div class="dropdown">
              <button
                class="btn btn-primary dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                Nouvelle opération
              </button>
              <div
                class="dropdown-menu p-0"
                style="width: 100%; font-size: 15px"
                aria-labelledby="dropdownMenuButton"
              >
                <button
                  @click.prevent="
                    actionStatus = 'add';
                    operationType = 'deposit';
                    showModal();
                  "
                  class="dropdown-item text-success d-block p-2 border-bottom"
                >
                  <i class="fas fa-fw fa-plus"></i> Un Dépôt
                </button>
                <button
                  @click.prevent="
                    actionStatus = 'add';
                    operationType = 'withdrawal';
                    showModal();
                  "
                  class="dropdown-item text-danger d-block p-2"
                  href="#"
                >
                  <i class="fas fa-minus"></i> Un Retrait
                </button>
              </div>
            </div>
          </div>
          <hr />
          <div>
            <base-remove-filter-button
              :rendered="show_remove_filter"
              customClass="btn btn-info"
              :all="allCash"
            />
          </div>
          <div class="row border-bottom no-gutters">
            <table
              style="font-size: 15px"
              class="table global-table table-striped table-hover table-sm bg-white table-reponsive border-none"
            >
              <thead style="font-size: 15px">
                <tr>
                  <th scope="col d-flex" class="p-3">
                    <Datepicker
                      class="d-inline"
                      v-model="date"
                      range
                      @handleDate="searchThrowCash"
                    >
                      <template #trigger>
                        <button
                          class="btn btn-default"
                          title="filtrer par date"
                          style="font-size: 15px; font-weight: 500"
                        >
                          <i class="fas fa-filter text-muted"></i> Date
                        </button>
                      </template>
                    </Datepicker>
                  </th>
                  <th scope="col" class="p-3">
                    <div class="dropdown dropdwon-sm">
                      <span
                        style="font-size: 15px"
                        type="button"
                        id="dropdown-type"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i class="fas fa-filter text-muted"></i> Type
                      </span>
                      <div
                        class="dropdown-menu p-0 text-center"
                        style="width: 100%; font-size: 15px"
                        aria-labelledby="dropdown-type"
                      >
                        <button
                          @click.prevent="searchThrowCash('deposit', 'type')"
                          class="dropdown-item text-success d-block p-2 border-bottom"
                        >
                          Dépôt
                        </button>
                        <button
                          @click.prevent="searchThrowCash('withdrawal', 'type')"
                          class="dropdown-item text-danger d-block p-2 border-bottom"
                        >
                          Retrait
                        </button>
                        <button
                          @click.prevent="searchThrowCash('verification', 'type')"
                          class="dropdown-item text-warning d-block p-2 border-bottom"
                        >
                          verification
                        </button>
                      </div>
                    </div>
                  </th>
                  <th scope="col" class="p-3">
                    <div
                      class="dropdown dropdwon-sm"
                      @click="focusOnClick('amount-input')"
                    >
                      <span
                        style="font-size: 15px"
                        type="button"
                        id="dropdown-amount"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i class="fas fa-filter text-muted"></i> Montant
                      </span>
                      <div
                        class="dropdown-menu p-3"
                        style="width: 100%"
                        aria-labelledby="dropdown-amount"
                      >                          
                          <input
                            type="button"
                            value="moins que"
                            class="btn btn-primary btn-sm px-2 mr-1 mb-1 w-100"
                            :class="{ 'btn-success ': symbol == '2' }"
                            @click.prevent="symbol = '2'"
                          />
                          <input
                            type="button"
                            value="égal"
                            class="btn btn-primary btn-sm px-2 mr-1 mb-1 w-100"
                            :class="{ 'btn-success ': symbol == '1' }"
                            @click.prevent="symbol = '1'"
                          />

                          <input
                            type="button"
                            value="plus que"
                            class="btn btn-primary btn-sm px-2 mr-1 mb-1 w-100"
                            :class="{ 'btn-success ': symbol == '3' }"
                            @click.prevent="symbol = '3'"
                          />
                        <input
                          type="number"
                          class="form-control mt-1"
                          style="max-height: 30px"
                          v-model="amount"
                          id="amount-input"
                        />
                        <div class="d-flex justify-content-between">
                        <button
                          class="btn btn-primary btn-sm  mt-2"
                          @click.prevent="searchThrowCash(amount, 'amount')"
                        >
                          Filtrer
                        </button>
                        <button
                          class="btn btn-danger btn-sm  mt-2"
                          @click.prevent="allCash"
                        >
                          Annuler
                        </button>
                        
                        </div>
                      </div>
                    </div>
                  </th>
                  <th
                    scope="col"
                    class="p-3"
                    v-for="(filter, i) in ['created_by', 'notes']"
                    :key="filter"
                  >
                    <app-input-dropdown
                      :filterName="filter"
                      :searchThrowCash="searchThrowCash"
                      :focusOnClick="focusOnClick"
                      :customLabel="['Responsable', 'Notes'][i]"
                    />
                  </th>
                  <th class="text-center py-3">Actions</th>
                </tr>
              </thead>
              <tbody v-if="filteredCash.length">
                <tr v-for="cashier in filteredCash" :key="cashier.id">
                  <app-cash-composition
                    :cashier="cashier"
                    :confirmDelete="confirmDelete"
                    :editOperation="editOperation"
                    :user_has_access_to_delete="user_has_access_to_delete"
                    :users="users"
                  />
                </tr>
              </tbody>
              <tbody v-else>
                <tr class="mt-2">
                  <td colspan="6">
                    <base-empty-result />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row justify-content-end no-gutters mt-3" v-if="filteredCash.length">
            <div class="col-6 text-right">
              <paginate
                v-if="pageCount > 1"
                v-model="page"
                :page-count="pageCount"
                :page-range="2"
                :margin-pages="2"
                :click-handler="paginate"
                :prev-text="'Précédent'"
                :next-text="'Suivant'"
                :container-class="'pagination'"
                :page-class="'page-item'"
                class="float-right p-0 m-0"
              >
              </paginate>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <base-modal-bootstrap
    :title="
      actionStatus == 'add'
        ? `Ajouter un ${operationTypeLable} `
        : `Mettre à jour le ${operationTypeLable}`
    "
    ref="bootstrapModal"
  >
    <template v-if="actionStatus == 'add'" v-slot:content>
      <MakeOperation
        :storeId="storeId"
        @getCash="getCashiers"
        :type="operationType"
      ></MakeOperation>
    </template>
    <template v-else v-slot:content>
      <EditOperation :storeId="storeId" @getCash="getCashiers"></EditOperation>
    </template>
  </base-modal-bootstrap>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from "vuex";
import Paginate from "vuejs-paginate-next";
import moment from "moment";
import MakeOperation from "@/views/cashier/MakeOperation.vue";
import EditOperation from "@/views/cashier/EditOperation.vue";
import AppInputDropdown from "@/views/cashier/InputDropdown";
import AppCashComposition from "@/views/cashier/CashComposition";

import CashierService from "@/services/cashier.service";
const cashierService = new CashierService();

import mixins from "@/includes/mixins";

export default {
  name: "Cashier",
  mixins: [mixins],
  components: {
    MakeOperation,
    EditOperation,
    Paginate,
    AppInputDropdown,
    AppCashComposition,
  },
  data() {
    return {
      date: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
      cashiers: [],
      filteredCash: [],
      filteredActiveCash: [],
      page: 1,
      per_page: 20,
      pageCount: 0,
      cashBalance: 0,
      storeId: null,
      actionStatus: "add",
      isLoading: false,
      currentPageName: "Journal de caisse",
      operationType: "deposit",
      expected_balance: null,
      user_has_access_to_delete: false,
      amount: null,
      created_by: null,
      notes: null,
      show_remove_filter: false,
      symbol: 1,
    };
  },
  created() {
    this.getCashiers();
    this.users = this.getApiData.settings.users;
    this.storeId = this.getApiData.settings.store.id;
    this.user_has_access_to_delete = this.getApiData.settings.permissions[
      "pos.receipt.delete"
    ];
  },
  methods: {
    numFormat(number) {
      return this.$filters.numFormat(number);
    },
    focusOnClick(id) {
      setTimeout(() => {
        document.getElementById(id).focus();
      }, 300);
    },
    mouseleave(id) {
      setTimeout(() => {
        $(`#${id}`).dropdown("hide");
      }, 3000);
    },
    ...mapMutations("cash", {
      setCashToEdit: "setCashToEdit",
    }),
    ...mapActions("cash", {
      setCaisseBalance: "setCaisseBalance",
    }),
    confirmDelete(id, type) {
      this.$swal
        .fire({
          title: "Es-tu sûr?",
          text: "Vous ne pourrez pas revenir en arrière!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Oui, supprime-le!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.deleteOperation(id, type);
          }
        });
    },
    editOperation(cash) {
      this.setCashToEdit(cash);
      this.actionStatus = "edit";
      this.showModal();
    },
    allCash() {
      this.filteredActiveCash = _.filter(this.cashiers, function (o) {
        return o.status === "active";
      });
      this.paginate();
      this.show_remove_filter = false;
    },
    searchThrowCash(keyword, field = null) {
      let result = [];
      this.show_remove_filter = true;
      if (typeof keyword === "object") {
        this.dateFilter = true;
        this.cashiers.map((o) => {
          if (
            moment(o.created_at).isBetween(keyword[0], keyword[1]) ||
            moment(o.created_at).format("YYYY-MM-DD") ===
              moment(keyword[1]).format("YYYY-MM-DD")
          ) {
            result.push(o);
          }
        });
      } else {
        switch (field) {
          case "type":
            result = _.filter(this.cashiers, (o) => _.includes(o.type, keyword));
            break;
          case "amount":
            if (this.symbol === "1") {
              result = _.filter(this.cashiers, (o) => o.amount === keyword);
            } else if (this.symbol === "3") {
              result = _.filter(this.cashiers, (o) => o.amount > keyword);
            } else if (this.symbol === "2") {
              result = _.filter(this.cashiers, (o) => o.amount < keyword);
            }
            break;
          case "created_by":
            result = _.filter(this.cashiers, (o) =>
              _.includes(
                this.toLowerCase(this.getUserNamebyId(o.created_by)),
                this.toLowerCase(keyword)
              )
            );
            break;
          case "notes":
            result = _.filter(this.cashiers, (o) =>
              _.includes(this.toLowerCase(o.notes), this.toLowerCase(keyword))
            );
            break;
          default:
            result = [];
            break;
        }
      }
      this.filteredActiveCash = _.filter(result, function (o) {
        return o.status === "active";
      });
    },
    paginate() {
      this.filteredCash = this.filteredActiveCash
        .slice((this.page - 1) * this.per_page)
        .slice(0, this.per_page);
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 1000);
    },
    async deleteOperation(id, type) {
      try {
        await cashierService.delete(id);
        this.$swal.fire("Supprimé!", `Ce ${type} a été supprimé`, "success");
        this.getCashiers();
      } catch (err) {
        this.$swal.fire("Supprimé!", "cet enregistrement n'a pas été supprimé.", "error");
        console.log(err);
      }
    },
    async getCashiers() {
      this.isLoading = true;
      try {
        const res = await cashierService.get();
        this.cashiers = res.data;
        this.filteredActiveCash = _.filter(res.data, function (o) {
          return o.status === "active";
        });
      } catch (err) {
        console.log(err);
      }
      try {
        const res = await cashierService.getBalance(this.storeId);
        this.expected_balance = res.data;
        let payload = {
          expected_balance: this.expected_balance,
        };
        this.setCaisseBalance(payload);
      } catch (err) {
        console.log(err);
      }
      this.isLoading = false;
    },
    toLowerCase(keyword) {
      if (keyword) return keyword.toLowerCase();
      return keyword;
    },
    getUserNamebyId(id) {
      let user = _.find(this.users, { id });
      return user.fullname;
    },
    showModal() {
      this.$refs.bootstrapModal.showModal();
    },
  },
  computed: {
    ...mapGetters(["getApiData"]),
    operationTypeLable() {
      return this.operationType === "deposit" ? "dépôt" : "retrait";
    },
  },
  watch: {
    date(newValue) {
      this.searchThrowCash(newValue);
    },
    filteredActiveCash(newValue) {
      this.page = 1;
      this.pageCount = Math.ceil(newValue.length / this.per_page);
      this.paginate();
    },
  },
};
</script>
<style>
.filter-icon i:hover {
  color: black !important;
}
.dropdwon-sm {
  font-size: small;
}
</style>
