<template>
    <div
    
      class="dropdown dropdwon-sm"
      @click="focusOnClick(filterName + '-input')"
    >
      <span
        type="button"
        id="dropdown-created_by"
        data-toggle="dropdown"
        aria-expanded="false"
         style="font-size:15px"
      >
        <i class="fas fa-filter text-muted"></i> {{ customLabel }}
      </span>
      <div
        class="dropdown-menu p-3 text-center"
        style="width: 100%; font-size: 15px"
        aria-labelledby="dropdown-created_by"
      >
        <input
          type="text"
          :id="filterName + '-input'"
          class="form-control"
          v-model="model"
          @keyup="searchThrowCash(model, filterName)"
        />
      </div>
    </div>
</template>

<script>
export default {
  name: "InputDropdown",
  props: {
    searchThrowCash: {
      type: Function,
      required: true,
    },
    focusOnClick: {
      type: Function,
      required: true,
    },
    filterName: {
      type: String,
      required: true,
    },
    customLabel: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      model: null,
    };
  },
};
</script>
<style scoped>
.filter-icon i:hover{
   color: black !important;
}
</style>