<template>
  <div class="container-fluid">
    <div class="row mt-3">
      <div class="col-12">
        <base-breadcrumb :pageName="currentPageName" :storeName="''" />
      </div>
    </div>
    <div class="row justify-content-around mb-3">
      <div class="col-md-6 col-sm-12 text-center">
        <div class="bg-white shadow border p-3">
          <h3 class="text-info">Fond de caisse attendu</h3>
          <h5>
            {{ $filters.numFormat(getBalances.expected_balance) }}
            {{ currency }}
          </h5>
        </div>
      </div>
      <div class="col-md-6 col-sm-12 text-center">
        <div class="bg-white shadow border p-3">
          <h3 class="text-info">Fond du tiroir-caisse</h3>
          <h5>{{ $filters.numFormat(storeBalance) }} {{ currency }}</h5>
        </div>
      </div>
    </div>
    <vee-form
      v-if="coins.length"
      @submit="onSubmit"
      :validation-schema="schema"
      v-slot="{ errors }"
    >
      <div class="row justify-content-between">
        <div class="col-md-6">
          <div class="my-3">
            <p class="text-center font-weight-bold">
              L'écart entre le fond de caisse attendu et votre tiroir-caisse est de:
              <br />
              <span :class="classObject"
                >{{ $filters.numFormat(difference) }} {{ currency }}</span
              >
            </p>
          </div>
          <div class="form-group">
            <label>Commentaire:</label>
            <vee-field
              rows="8"
              :class="{ 'is-invalid': errors.comment }"
              name="comment"
              class="form-control"
            />
            <ErrorMessage name="comment" class="text-danger" />
          </div>
        </div>
        <div class="col-md-6">
          <label>Entrez La Quantité des pièces:</label>
          <div v-for="(coin, i) in coins" :key="coin">
            <div class="row">
              <div class="col-2">
                <label class="p-1 font-weight-bold">{{ coinIndex(coin) }} </label>
              </div>
              <div class="col-6">
                <input
                  :name="coin"
                  v-model="coins[i]['coin_' + coinIndex(coin)]"
                  type="number"
                  class="form-control form-control-sm w-50"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 text-left">
          <button
            type="submit"
            class="btn btn-primary btn-block"
            :disabled="cashChecking_submission"
          >
            <i class="fas fa-save"></i> Enregistrer
          </button>
        </div>
      </div>
    </vee-form>
    <div class="alert alert-danger p-4 mx-2 text-center" v-else>
      s'il vous plaît assurez-vous que vous avez des
      <strong>Pièces</strong> ajoutées sur le paramètre global Manageo
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import CashierService from "@/services/cashier.service";
const cashierService = new CashierService();

export default {
  name: "CashChecking",
  data() {
    return {
      cashChecking_submission: false,
      currency: null,
      currentPageName: "vérification de caisse",
      storeBalance: 0,
      storeId: null,
      coins: [],
      schema: {
        comment: "required",
      },
    };
  },
  created() {
    this.isLoading = true;
    let { settings } = this.getApiData;
    if (Object.keys(settings).length) {
      this.currency = settings.organization.currency;
      this.storeId = settings.store.id;
      this.coins = settings.store.coins;
      let formatedCoins = [];
      for (let i = 0; i < Object.keys(this.coins).length; i++) {
        let key = `coin_${Object.values(this.coins)[i]}`;
        formatedCoins = [...formatedCoins, { [key]: 0 }];
      }
      this.coins = formatedCoins;
    }
  },
  methods: {
    async onSubmit(values) {
      this.cashChecking_submission = true;
      let data = {
        store_id: this.storeId,
        type: "verification",
        notes: values.comment,
        amount: this.difference,
      };

      try {
        await cashierService.store(data);

        this.$toast.success("Vérification effectuée!", {
          position: "top-right",
          timeout: 5000,
        });
        this.$router.push("/Cashier");
      } catch (error) {
        this.$toast.error("Verefiaction echouee!", {
          position: "top-right",
          timeout: 5000,
        });
      }
      this.cashChecking_submission = false;
    },
    getFirstObjectkey(object) {
      return Object.keys(object)[0];
    },
    coinIndex(coin) {
      let data = Object.keys(coin)[0].split("_");
      return data.slice(-1)[0];
    },
  },
  computed: {
    ...mapGetters(["getApiData"]),
    ...mapGetters("cash", {
      getBalances: "getBalances",
    }),
    difference() {
      return this.storeBalance - this.getBalances.expected_balance;
    },
    classObject() {
      return {
        "text-danger": this.difference < 0,
        "text-success": this.difference > 0,
        "text-primary": this.difference == 0,
      };
    },
  },
  watch: {
    coins: {
      handler(val) {
        let result = 0;
        for (let i = 0; i < Object.keys(val).length; i++) {
          let value;
          if (Object.values(val[i])[0] === "") {
            value = 0;
          } else {
            value = parseInt(Object.values(val[i])[0]);
          }
          result += this.coinIndex(val[i]) * value;
        }
        this.storeBalance = result;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style>
.custom-button {
  text-align: start;
  margin-right: auto;
  margin-left: 1rem;
}
</style>
